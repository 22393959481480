let origin = window.location.origin;

if (import.meta.env.VITE_APP_FQDN) {
    origin = `https://${import.meta.env.VITE_APP_FQDN}`;
    console.log(`APP_ORIGIN=${origin}`);
}

// currently read from .env.local generated by justfile
if (import.meta.env.VITE_APP_ORIGIN) {
    origin = `https://${import.meta.env.VITE_APP_ORIGIN}`;
    console.log(`APP_ORIGIN=${origin}`);
}

export const APP_ORIGIN = origin;
